.form {
    width: 100%;

    @include modifier("collapse-margin-bottom") {
        align-content: flex-start;
        margin-bottom: auto;
        min-height: rem(500px);
    }

    @include modifier("select-language") {
        border-top: rem(1px) solid #c9d5dd;
        margin-top: rem(10px);
        padding-top: rem(25px);
    }

    @include element("input-text") {
        background-color: $form-input-text-background-color;
        border:
            rem($form-input-text-border-width)
            $form-input-text-border-style
            $form-input-text-border-color;
        border-radius: rem($form-input-text-border-radius);
        color: $form-input-text-color;
        font-family: $form-input-text-font-family;
        font-size: rem($form-input-text-font-size);
        margin: rem($form-input-text-margin);
        outline: none;
        padding: rem($form-input-text-padding);
        width: 100%;

        &:focus {
            border:
                rem($form-input-text-focus-border-width)
                $form-input-text-focus-border-style
                $form-input-text-focus-border-color;
        }

        &::placeholder {
            color: $form-input-text-placeholder-color;
        }

        @include modifier("error") {
            border-color: $form-input-text-error-border-color;
            color: $form-input-text-error-color;

            &:focus {
                border-color: $form-input-text-error-border-color;
            }
        }
    }

    @include element("label") {
        display: inline-block;
        font-size: rem(18px);
        font-weight: 700; // Bold
        line-height: normal;
        margin-bottom: rem(15px);
        width: 100%;

        font-family: "Poppins", sans-serif;
        color: $theme-primary-color;

        @include element("order") {
            color: $theme-primary-color-extralight;
            margin-right: rem(8px);
        }
    }

    // Style definitions for React-Select (https://react-select.com/)
    @include element("select") {
        font-family: $form-select-font-family !important;
        font-size: rem($form-select-font-size) !important;
        font-weight: $form-select-font-weight !important;
        line-height: $form-select-line-height !important;
        border-color: $theme-primary-color !important;

        @include modifier("error") {
            .form__select__control {
                border-color: $form-input-text-error-border-color !important;
            }
        }

        @include element("multi-value") {
            background-color: #dcf2ff !important;
            border-radius: rem(12px) !important;

            &:hover {
                background-color: #b3e3ff !important;
            }

            @include element("label") {
                color: #515151 !important;
                font-size: rem(12px) !important;
                padding: rem(5px 6px 5px 12px) !important;
            }

            @include element("remove") {
                border-radius: 50% !important;
                padding: rem(5px) !important;

                &:hover {
                    background-color: transparent !important;
                    color: #515151 !important;
                }
            }
        }

        @include element("control") {
            border:
                rem($form-select-control-border-width)
                $form-select-control-border-style
                $form-select-control-border-color !important;
            border-radius: rem($form-select-control-border-radius) !important;

            @include modifier("is-focused") {
                border:
                    rem($form-select-control-is-focused-border-width)
                    $form-select-control-is-focused-border-style
                    $form-select-control-is-focused-border-color !important;
                box-shadow: none !important;
            }
        }

        @include element("clear-indicator") {
            padding: rem($form-select-clear-indicator-padding) !important;
        }

        @include element("dropdown-indicator") {
            padding: rem($form-select-dropdown-indicator-padding) !important;
        }

        @include element("menu") {
            box-shadow: none !important;
            border-radius: rem($form-select-menu-border-radius) !important;
            margin-top: 0 !important;
        }

        @include element("menu-list") {
            border:
                rem($form-select-menu-list-border-width)
                $form-select-menu-list-border-style
                $form-select-menu-list-border-color !important;
            padding: $form-select-menu-list-padding !important;
        }

        @include element("option") {
            background-color: $form-select-option-background-color !important;
            line-height: $form-select-option-line-height !important;
            padding: rem($form-select-option-padding) !important;

            @include modifier("is-focused") {
                background-color: $form-select-option-is-focused-background-color !important;
            }

            @include modifier("is-selected") {
                background-color: $form-select-option-is-selected-background-color !important;
                background-image: $form-select-option-is-selected-background-image !important;
                background-repeat: $form-select-option-is-selected-background-repeat !important;
                background-position: right rem(12px) center !important;
                background-size: rem($form-select-option-is-selected-background-size) !important;
                color: $form-input-text-color !important;
                padding: rem($form-select-option-is-selected-padding) !important;

                @include for-device-pixel-ratio(2) {
                    & {
                        background-image: $form-select-option-is-selected-background-image-2x !important;
                    }
                }

                @include for-device-pixel-ratio(3) {
                    & {
                        background-image: $form-select-option-is-selected-background-image-3x !important;
                    }
                }
            }
        }

        @include element("placeholder") {
            color: $form-select-placeholder-color !important;
        }

        @include element("value-container") {
            padding: rem($form-select-value-container-padding) !important;

            @include modifier("is-multi") {
                align-items: flex-start !important;
                flex-direction: column !important;
            }
        }
    }

    @include element("textarea") {
        border:
            rem($form-textarea-border-width)
            $form-textarea-border-style
            $form-textarea-border-color;
        border-radius: rem($form-textarea-border-radius);
        font-family: $form-textarea-font-family;
        font-size: rem($form-textarea-font-size);
        margin-top: rem(16px);
        min-height: rem($form-textarea-min-height);
        outline: none;
        padding: rem($form-textarea-padding);
        width: 100%;

        &:focus {
            border:
                rem($form-textarea-focus-border-width)
                $form-textarea-focus-border-style
                $form-textarea-focus-border-color;
        }

        &::placeholder {
            color: $form-textarea-placeholder-color;
        }

        @include modifier("error") {
            border-color: $form-textarea-error-border-color;
            color: $form-textarea-error-color;

            &:focus {
                border-color: $form-textarea-error-border-color;
            }
        }

        @include modifier("small") {
            max-height: rem(150px);
            min-height: rem(100px);
        }
    }

    @include element("error") {
        background:
            $form-error-background-image
            $form-error-background-position
            $form-error-background-repeat
            $form-error-background-color;
        background-size: rem($form-error-background-size);
        color: $form-error-color;
        font-size: rem($form-error-font-size);
        font-weight: $form-error-font-weight;
        display: block;
        margin: rem(8px 0 4px 0);
        padding-left: rem($form-error-padding-left);

        @include for-device-pixel-ratio(2) {
            & {
                background-image: $form-error-background-image-2x;
            }
        }

        @include for-device-pixel-ratio(3) {
            & {
                background-image: $form-error-background-image-3x;
            }
        }
    }

    @include element("ratinginput") {
      display: inline-block;
      width: $form-rating-input-image-dimension;
      height: $form-rating-input-image-dimension;
      background-image: $form-rating-input-empty-image;
      margin-top: rem(12px);
      opacity: 0.66;

      @include modifier("half") {
        background-image: $form-rating-input-half-image;
      }

      @include modifier("full") {
        background-image: $form-rating-input-full-image;
      }

      @include modifier("hover") {
        opacity: 0.5;
        background-image: $form-rating-input-full-image;
      }
    }

    @include element("submit") {
        @extend %button-shared;

        @include modifier("disabled") {
            @extend %button-disabled-shared;
        }
    }
}

@include breakpoint(medium down) {
    .form {
        @include element("label") {
            font-size: rem(14px);
        }

        @include element("submit") {
            @include modifier("floating-bottom-right") {
                bottom: rem(77px);
                left: 0;
                padding: rem(15px 20px);
                right: 0;
                width: 100%;
            }
        }

        @include element("textarea") {
            min-height: rem(round($form-textarea-min-height * 0.5));

            @include modifier("small") {
                min-height: rem(100px);
            }
        }
    }
}

@include breakpoint(small down) {
    .form {
        @include modifier("collapse-margin-bottom") {
            margin-bottom: 0;
            min-height: 0;
        }
    }
}
