.dashboard-change-plan-view {
  max-width: rem(1024px);
  margin-left: auto;
  margin-right: auto;
  margin-top: rem(60px);

  .pricing-option-select-container {
    .stripe-price-amount {
      font-weight: 600;
      color: $theme-primary-color;
      display: inline-block;
      margin-right: rem(4px);
    }

    .stripe-price-billing-interval, .stripe-price-tax {
      color: $theme-primary-color;
      font-weight: normal;
      font-size: rem(14px);
    }

    .discount-info {
      display: inline-block;
      color: orangered;
      font-weight: bold;
      font-family: "Poppins", sans-serif;
      margin-left: rem(20px);
    }
  }

  .change-plan-preview-section {
    margin-bottom: rem(64px);
  }

  .loading-proration-preview {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: rem(150px);
  }

  .payment-card-container {
    display: flex;
    align-items: center;

    .checkmark {
      font-size: rem(20px);
      margin-right: rem(8px);
      color: $theme-primary-color-lighter;
    }

    .card-payment-method {
      margin-bottom: 0;
      width: rem(450px);
    }
  }

  .change-plan-view-actions {
    margin-bottom: rem(60px);
  }
}

.subscription-proration-preview {
  ul {
    list-style-type: none;
  }

  .proration-header {
    .cell {
      text-transform: uppercase;
      font-weight: 700;
      color: $theme-primary-color;
    }
  }

  .proration-product-line {
    padding-top: rem(8px);
    padding-bottom: rem(8px);
    border-top: 1px solid #aaa;

    &:last-child {
      border-bottom: 1px solid #aaa;
    }
  }

  .subscription-proration-totals-preview {
    .grand-total-line {
      font-weight: 600;
      font-size: rem(20px);
      margin-top: rem(12px);
    }
  }
}
