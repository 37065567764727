.quantity-select {
  display: flex;
  align-items: center;

  .increment, .decrement {
    width: rem(32px);
    height: rem(32px);
    border-radius: rem(16px);
    background-color: $theme-primary-color-lighter;
    color: white;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .input-container {
    margin-left: rem(20px);
    margin-right: rem(20px);
    width: 60px;

    input {
      text-align: center;
      padding: rem(12px);
      border: none;
      width: 100%;
      background-color: $theme-primary-color-extralight;
      color: $theme-primary-color;
      font-weight: bold;
      font-size: rem(18px);
      border-radius: rem(8px);

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      &[type=number] {
        -moz-appearance: textfield;
      }
    }
  }
}