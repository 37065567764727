.deck-preview-window {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 100;

  .deck-preview-background {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba($color: #000000, $alpha: 0.4);
  }

  .deck-preview-content {
    background: white;
    width: rem(800px);
    max-width: 100%;

    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
  }

  .deck-preview-close-button {
    font-size: rem(20px);
    color: white;
    width: rem(40px);
    height: rem(40px);
    border-radius: rem(20px);
    background-color: $theme-secondary-action-color;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 10;
    position: fixed;
    right: rem(750px);
    top: rem(10px);
    
    &:hover {
      transform: scale(1.2);
      background-color: darken($theme-secondary-action-color, 15%);
    }
  }
}
