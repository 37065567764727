.consume-product-modal-content {
  background: $theme-primary-color-extralight;
  padding: rem(20px);
  border-radius: rem(8px);
  margin-top: rem(40px);
  margin-bottom: rem(40px);

  .cell {
    display: flex;
    align-items: center;
  }

  .plain-select {
    width: 100%;
  }
}