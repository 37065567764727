.dashboard-product-marketing-view {
  .dashboard-product-marketing-view-content {
    width: rem(1200px);
    max-width: calc(100% - 40px);
    margin: auto;
    margin-top: rem(60px);

    .quantity-container {
      margin-top: rem(40px);
      display: flex;
      flex-direction: row;
      align-items: center;

      .quantity-select {
        margin-left: rem(20px);
      }
    }

    .actions-container {
      margin-top: rem(40px);
      margin-bottom: rem(60px);
    }
  }
}