@import "../../assets/stylesheets/core/dependencies.scss";

.deck-viewer-layout {
  display: flex;
  width: 100%;

  .content-left {
    min-width: rem(350px);
    flex-shrink: 0;

    @media(max-width: 1024px) {
      min-width: 0;
      font-size: 0.8em;
    }

    .deck-catalog-list-container {
      position: sticky;
      top: 20px;
      display: flex;
      flex-direction: column;
      height: 100%;

      .deck-filters {
        flex: 0 0 auto;
      }
      .deck-catalog-list-container-inner {
        height: 70%;
        flex: 1 1 auto;
        overflow-y: scroll;
      }
    }
  }

  .content-right {
    max-width: 100%;
    flex-grow: 1;
    overflow-x: hidden;

    .dashboard-info-message {
      margin-top: 0;
      margin-left: 40px;
    }
  }
}

.deck-select-window-back-arrow {
  font-size: rem(20px);
  color: white;
  width: rem(40px);
  height: rem(40px);
  border-radius: rem(20px);
  background-color: #435B68;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
  position: absolute;
  left: rem(400px);
  top: rem(10px);
  
  &:hover {
    transform: scale(1.2);
    background-color: #2A3941;
  }
}

.deck-language-filter {
  ul {
    width: 100%;
  }

  .deck-filter-language-select {
    width: 100%;
    margin-bottom: rem(20px);
    margin-top: rem(10px);

    .deck-filter-language-select__value-container {
      height: 50px;
    }
  }

  .deck-language-filter-option {
    line-height: rem(18px);

    div:first-child {
      font-size: rem(14px);
      color: $theme-primary-color;
    }

    div:last-child {
      color: #2A3941;
      font-size: rem(14px);
      color: $theme-primary-color-lighter;
    }
  }

  .checkbox-input {
    display: flex;
    margin-top: 0;

    li {
      flex: 1;
    }

    li:first-child {
      margin-right: rem(8px);
    }
  }
}
