.message {
    background-color: $message-background-color;
    border: rem($message-border-width) $message-border-style $message-border-color;
    margin: rem(80px auto 0);
    max-width: rem($message-max-width);
    padding: rem($message-padding);
    position: relative;
    z-index: 1000;

    @include modifier("error") {
        border-left: rem($message-error-border-left-width) $message-error-border-left-style $message-error-border-left-color;
        padding: rem($message-error-padding);

        &::before {
            background:
                $message-error-before-background-image
                $message-error-before-background-position
                $message-error-before-background-repeat
                $message-error-before-background-color;
            border: rem($message-error-before-border-width) $message-error-before-border-style $message-error-before-border-color;
            background-size: rem($message-error-before-background-size);
            border-radius: 100%;
            content: "";
            display: block;
            height: rem($message-error-before-height);
            left: rem(24px);
            position: absolute;
            top: rem(24px);
            width: rem($message-error-before-width);

            @include for-device-pixel-ratio(2) {
                & {
                    background-image: $message-error-before-background-image-2x;
                }
            }

            @include for-device-pixel-ratio(3) {
                & {
                    background-image: $message-error-before-background-image-3x;
                }
            }
        }
    }

    @include element("content") {
        color: $message-content-color;
        font-size: rem($message-content-font-size);
        line-height: $message-content-line-height;
        margin: $message-content-margin;
    }

    @include element("heading") {
        font-size: rem($message-heading-font-size);
        font-weight: $message-heading-font-weigth;
        line-height: $message-heading-line-height;
        margin: $message-heading-margin;

        @include modifier("error") {
            color: $message-heading-error-color;
        }
    }
}
