#react-confirm-alert {
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  transform: translateZ(10000px);

  .react-confirm-alert-overlay {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0; left: 0; right: 0; bottom: 0;
    background: rgba($color: $theme-primary-color, $alpha: 0.4);

    .react-confirm-alert {
      background: white;
      padding: rem(20px);
      border-radius: rem(4px);
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      margin: auto;
      width: 450px;
      max-width: 100%;
    }

    .confirm-dialog {
      h1 {
        font-size: 22px;
        text-transform: uppercase;
        color: $theme-complementary-color;
        font-weight: 600;
        text-align: center;
      }
      p {
        margin-bottom: rem(20px);
        color: $theme-primary-color;
      }
      .actions {
        text-align: center;

        button:not(:last-child) {
          margin-right: rem(16px);
        }
        button {
          margin-bottom: rem(8px);
        }

        .cancel-button {
          background: none;
          text-decoration: underline;
          text-transform: none;
          box-shadow: none;
          color: $theme-primary-color-lighter;
          font-size: rem(14px);
        }
      }
    }
  }
}

#react-confirm-alert-firm-svg {
  display: none;
}

.react-confirm-alert-body-element {
  background: none;
  overflow: hidden;
}

.react-confirm-removed-item-title {
  padding: rem(16px);
  background: $theme-primary-color-extralight;
  color: $theme-primary-color;
  font-weight: 600;
  margin-bottom: rem(20px);
  overflow-wrap: break-word;
}
