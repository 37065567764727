.custom-content-sidebar-wrapper {
  $width: 700px;

  .custom-content-sidebar {
    position: fixed;
    width: $width;
    max-width: 100%;
    right: 0px;
    top: 0px;
    bottom: 0px;
    background: white;
    box-shadow: -4px 0px 10px rgba($color: $theme-primary-color, $alpha: 0.5);
    padding: 20px;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 1000;
  }
  
  .custom-content-close-button {
    $size: 30px;
    position: fixed;
    width: $size;
    height: $size;
    top: 12px;
    right: $width - $size * 0.5;
    border-radius: 50%;
    background: $theme-complementary-color;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    z-index: 1001;

    @media(max-width: 800px) {
      right: unset;
      left: 10px;
    }
  }
}
