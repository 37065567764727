@use "sass:math";

@function rem($values) {
  $output: ();
  @each $value in $values {
      @if ($value == 0 or (type-of($value) == "number" and unit($value) == "%")
          or $value == auto or $value == inherit
      ) {
          $output: append($output, $value);
      } @else {
          $output: append($output, math.div($value, $base-font-size) + rem);
      }
  }

  @return $output;
}

@mixin element($element) {
    &__#{$element} {
        @content;
    }
}

@mixin font-face($font-family, $path, $filename, $svg-id, $font-style: normal, $font-weight: normal) {
  @font-face {
      font-family: "#{$font-family}";
      src: url("#{$path}/#{$filename}.eot");
      src: url("#{$path}/#{$filename}.eot?iefix") format("embedded-opentype"),
           url("#{$path}/#{$filename}.woff2") format("woff2"),
           url("#{$path}/#{$filename}.woff") format("woff"),
           url("#{$path}/#{$filename}.ttf") format("truetype"),
           url("#{$path}/#{$filename}.svg##{$svg-id}") format("svg");
      font-style: $font-style;
      font-weight: $font-weight;
  }
}

@mixin for-device-pixel-ratio($level) {
    $device-pixel-ratio-to-min-resolution: (
        2: 192dpi,
        3: 288dpi
    );

    @if (variable-exists(level) and map-has-key($device-pixel-ratio-to-min-resolution, $level)) {
        @media only screen and (-webkit-min-device-pixel-ratio: $level), (min-resolution: map-get($device-pixel-ratio-to-min-resolution, $level)) {
            @content;
        }
    } @else {
        @content;
    }
}

@mixin modifier($modifier) {
    &--#{$modifier} {
        @content;
    }
}

@mixin cards-highlight-shared {
    box-shadow: none;
    opacity: 1.00;
    cursor: pointer;
  
    &::before {
      background-color: #ffa400; // Fallback
      background-image: linear-gradient(to right, #f76b1c, #ffa400);
      border-radius: rem(8px);
      box-shadow: rem(1px 2px 3px 0) rgba(0, 0, 0, 0.32);
      content: "";
      display: block;
      height: rem(250px);
      position: absolute;
      top: rem(-5px);
      left: rem(-5px);
      width: rem(210px);
      z-index: -1;
      transform: translateZ(10px);
    }
  }
  
  %cards-text-shared {
    color: #515151;
    font-family: $primary-font-family;
    line-height: normal;
    margin: 0;
    text-align: center;
  }