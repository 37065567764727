.result-pager {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: 20px;

  &.empty {
    display: none;
  }

  .result-pager-page {
    width: 30px;
    height: 30px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    font-weight: 600;
    cursor: pointer;
    color: $theme-complementary-color;

    &.current-page {
      background-color: $theme-complementary-color;
      color: white;
    }

    &:hover:not(.current-page) {
      background: rgba($color: $theme-complementary-color, $alpha: 0.2);
    }

    &.focus-visible, &:focus-visible {
      outline: 2px solid $theme-complementary-color;
    }
  }

  .result-info {
    display: flex;
    align-items: center;
    font-size: 13px;
    color: #555;
    margin-left: 20px;
  }
}