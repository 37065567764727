/* Global variable definitions
---------------------------------------------------------------------------- */

@import "./breakpoints";

$body-background-color: #fff !default;
$body-background-image: url("~images/background.png") !default;
$body-background-image-2x: url("~images/background@2x.png") !default;
$body-background-image-3x: url("~images/background@3x.png") !default;
$body-background-position: 50% 50% !default;
$body-background-repeat: no-repeat !default;
$body-background-size: contain !default;
$body-color: #333 !default;

// Theme colors

$theme-primary-color: #444;
$theme-primary-color-lighter: #555;
$theme-primary-color-extralight: #AAA;
$theme-primary-color-extralight-tint: #F5FBFF;
$theme-primary-color-white: #f0f0f0;
$theme-primary-color-desaturated-white: #E6ECEF;
$theme-primary-color-darker: #222;
$theme-secondary-color: #486f86;
$theme-complementary-color: #FEA500;
$theme-action-color: #FEA500 !default;
$theme-secondary-action-color: #7796a8;
$theme-box-shadow-color: #0b3f53;
$theme-accent-color: #35baef;
$theme-accent-color-lighter: #afddf0;
$theme-accent-color-extralight: #cce5f0;
$theme-accent-two-color: #009871;
$theme-accent-three-color: #622b48;
$theme-accent-four-color: #073351;
$theme-error-color: orangered;

// Suit colors

$diamonds-color: #59aa3b;
$spades-color: #00587d;
$hearts-color: #e14970;
$clubs-color-light: #fbba00;
$clubs-color: #ff8400;

:root {
  --diamonds-color: #{$diamonds-color};
  --spades-color: #{$spades-color};
  --hearts-color: #{$hearts-color};
  --clubs-color: #{$clubs-color};
}

// Typography
$primary-font-family: "Work Sans", Arial, Helvetica, sans-serif !default;
$secondary-font-family: "Poppins", sans-serif !default;
$base-font-family: $primary-font-family !default;
$base-font-size: 16px !default;
$base-line-height: 1.5em !default;

$headings-shared-color: $theme-primary-color !default;
$headings-shared-font-weight: 700 !default; // Bold
$headings-shared-line-height: normal !default;

$link-color: $theme-complementary-color !default;
$link-color-hover: $theme-complementary-color !default;
$link-back-color: #515151 !default;
$link-back-color-hover: #7796a8 !default;
$link-back-font-size: 14px !default;

$text-color: #585858 !default;
$text-font-family: $primary-font-family !default;
$text-font-size: 16px !default;
$text-letter-spacing: 0.4px !default;
$text-line-height: 20px !default;

$text-h1-font-size: 42px !default;
$text-h1-line-height: 42px !default;
$text-h1-letter-spacing: 1px !default;
$text-h2-font-size: 24px !default;
$text-legend-font-size: 16px !default;
$text-legend-line-height: 20px !default;
$text-legend-margin-bottom: 60px !default;
$text-legend-link-color: #505050 !default;
$text-success-color: #67c100 !default;
$text-success-font-weight: 700 !default; // Bold

// Structure
// - Dialog

// Dialog
$dialog-background-color: #f7f8fa !default;
$dialog-border-radius: 16px !default;
$dialog-box-shadow-color: rgba(0, 0, 0, 0.50) !default;
$dialog-box-shadow-shadows: 0 15px 50px 0 !default;
$dialog-margin: auto !default;
$dialog-max-width: 1200px !default;
$dialog-padding: 80px !default;

// Components
// - Action Item
// - Button
// - Cards
// - Form
// - Message
// - Timer
// - SessionTopicBar

// Action Item
$action-item-background-color: transparent !default;
$action-item-background-image: url("~images/action-plan/action-item/remove.png") !default;
$action-item-background-image-2x: url("~images/action-plan/action-item/remove@2x.png") !default;
$action-item-background-image-3x: url("~images/action-plan/action-item/remove@3x.png") !default;
$action-item-background-position: 50% 50% !default;
$action-item-background-repeat: no-repeat !default;
$action-item-background-size: 11px 12px !default;

// Button
$button-border-color: #7796a8 !default;
$button-border-style: solid !default;
$button-border-width: 0px !default;
$button-color: #fff !default;
$button-font-family: $primary-font-family !default;
$button-font-size: 13px !default;
$button-font-weight: 600 !default; // Semi-Bold
$button-letter-spacing: 0.8px !default;
$button-min-width: 140px !default;
$button-padding: 15px !default;
$button-text-align: center !default;
$button-border-radius: 6px !default;
$button-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);

$button-disabled-background-color: #c9d5dd !default;
$button-disabled-border: none !default;
$button-disabled-color: #fff !default;

// Cards
$cards-background-color: #fdfdfd !default;

$cards-face-background-image: url("~images/cards/face.png") !default;
$cards-face-background-image-2x: url("~images/cards/face@2x.png") !default;
$cards-face-background-image-3x: url("~images/cards/face@3x.png") !default;

$cards-perspectives-background-color: #2c2c2d !default;
$cards-perspectives-background-image: url("~images/cards/back.png") !default;
$cards-perspectives-background-image-2x: url("~images/cards/back@2x.png") !default;
$cards-perspectives-background-image-3x: url("~images/cards/back@3x.png") !default;
$cards-perspectives-background-position: 50% 50% !default;
$cards-perspectives-background-repeat: no-repeat !default;
$cards-perspectives-background-size: 200px 240px !default;

$cards-topaasia-background-image: url("~images/cards/topaasia.png") !default;
$cards-topaasia-background-image-2x: url("~images/cards/topaasia@2x.png") !default;
$cards-topaasia-background-image-3x: url("~images/cards/topaasia@3x.png") !default;
$cards-topaasia-background-position: 50% 50% !default;
$cards-topaasia-background-size: 152px 22px !default;

// SessionTopicBar
$session-topic-background-color: #fff !default;
$session-topic-heading-color: #666 !default;
$session-topic-heading-font-family: $primary-font-family !default;
$session-topic-heading-font-size: 20px !default;

// Form
// - Shared style definitions
// - Errors
// - Input text
// - Select (React-Select overrides)
// - Textarea

// Form: Shared style definitions
$form-shared-background-color: #fff !default;
$form-shared-border-radius: 2px !default;
$form-shared-border-color: $theme-primary-color !default;
$form-shared-border-style: solid !default;
$form-shared-border-width: 1px !default;
$form-shared-color: #262626 !default;
$form-shared-error-border-color: #f13031 !default;
$form-shared-error-color: #f13031 !default;
$form-shared-focus-border-color: #5782fa !default;
$form-shared-focus-border-style: solid !default;
$form-shared-focus-border-width: 1px !default;
$form-shared-font-size: 14px !default;
$form-shared-placeholder-color: #585858 !default;

// Form: Errors
$form-error-background-color: transparent !default;
$form-error-background-image: url("~images/form/attention.png") !default;
$form-error-background-image-2x: url("~images/form/attention@2x.png") !default;
$form-error-background-image-3x: url("~images/form/attention@3x.png") !default;
$form-error-background-position: 0 50% !default;
$form-error-background-repeat: no-repeat !default;
$form-error-background-size: 12px 12px !default;
$form-error-color: $form-shared-error-color !default;
$form-error-font-size: $form-shared-font-size !default;
$form-error-font-weight: 700 !default; // Bold
$form-error-padding-left: 16px !default;

// Form: Input text
$form-input-text-background-color: $form-shared-background-color !default;
$form-input-text-border-radius: $form-shared-border-radius !default;
$form-input-text-border-color: $form-shared-border-color !default;
$form-input-text-border-style: $form-shared-border-style !default;
$form-input-text-border-width: $form-shared-border-width !default;
$form-input-text-color: $form-shared-color !default;
$form-input-text-font-family: $primary-font-family !default;
$form-input-text-font-size: $form-shared-font-size !default;
$form-input-text-margin: 0 !default;
$form-input-text-padding: 10px 8px !default;
$form-input-text-error-border-color: $form-shared-error-border-color !default;
$form-input-text-error-color: $form-shared-error-color !default;
$form-input-text-focus-border-color: $form-shared-focus-border-color !default;
$form-input-text-focus-border-style: $form-shared-focus-border-style !default;
$form-input-text-focus-border-width: $form-shared-focus-border-width !default;
$form-input-text-placeholder-color: $form-shared-placeholder-color !default;

// Form: Textarea
$form-textarea-border-radius: $form-shared-border-radius !default;
$form-textarea-border-color: $form-shared-border-color !default;
$form-textarea-border-style: $form-shared-border-style !default;
$form-textarea-border-width: $form-shared-border-width !default;
$form-textarea-font-family: $primary-font-family !default;
$form-textarea-font-size: $form-shared-font-size !default;
$form-textarea-min-height: 246px !default;
$form-textarea-padding: 12px !default;

$form-textarea-error-border-color: $form-shared-error-border-color !default;
$form-textarea-error-color: $form-shared-error-color !default;
$form-textarea-focus-border-color: $form-shared-focus-border-color !default;
$form-textarea-focus-border-style: $form-shared-focus-border-style !default;
$form-textarea-focus-border-width: $form-shared-focus-border-width !default;
$form-textarea-placeholder-color: $form-shared-placeholder-color !default;

// Form: Select (React-Select overrides)
$form-select-font-family: $primary-font-family !default;
$form-select-font-size: $form-shared-font-size !default;
$form-select-font-weight: 400 !default; // Regular
$form-select-line-height: normal !default;
$form-select-control-border-color: $form-shared-border-color !default;
$form-select-control-border-style: $form-shared-border-style !default;
$form-select-control-border-width: $form-shared-border-width !default;
$form-select-control-border-radius: $form-shared-border-radius !default;
$form-select-control-is-focused-border-color: $form-shared-focus-border-color !default;
$form-select-control-is-focused-border-style: $form-shared-focus-border-style !default;
$form-select-control-is-focused-border-width: $form-shared-focus-border-width !default;
$form-select-clear-indicator-padding: 0 8px !default;
$form-select-dropdown-indicator-padding: 0 8px !default;
$form-select-menu-border-radius: $form-shared-border-radius !default;
$form-select-menu-list-border-color: #c9d5dd !default;
$form-select-menu-list-border-style: $form-shared-border-style !default;
$form-select-menu-list-border-width: 1px !default;
$form-select-menu-list-padding: 0 !default;
$form-select-option-background-color: #fff !default;
$form-select-option-line-height: normal !default;
$form-select-option-padding: 10px 11px !default;
$form-select-option-is-focused-background-color: #f6f6f6 !default;
$form-select-option-is-selected-background-color: #f6f6f6 !default;
$form-select-option-is-selected-background-image: url("~images/form/selected.png") !default;
$form-select-option-is-selected-background-image-2x: url("~images/form/selected@2x.png") !default;
$form-select-option-is-selected-background-image-3x: url("~images/form/selected@3x.png") !default;
$form-select-option-is-selected-background-position: right 12px center !default;
$form-select-option-is-selected-background-repeat: no-repeat !default;
$form-select-option-is-selected-background-size: 14px 10px !default;
$form-select-option-is-selected-padding: 10px 30px 11px 10px !default;
$form-select-placeholder-color: $form-shared-placeholder-color !default;
$form-select-value-container-padding: 0 8px !default;

// Form: RatingInput
$form-rating-input-full-image: url("~images/star/star-full.png") !default;
$form-rating-input-full-image-2x: url("~images/star/star-full@2x.png") !default;
$form-rating-input-full-image-3x: url("~images/star/star-full@3x.png") !default;
$form-rating-input-half-image: url("~images/star/star-half.png") !default;
$form-rating-input-half-image-2x: url("~images/star/star-half@2x.png") !default;
$form-rating-input-half-image-3x: url("~images/star/star-half@3x.png") !default;
$form-rating-input-empty-image: url("~images/star/star-empty.png") !default;
$form-rating-input-empty-image-2x: url("~images/star/star-empty@2x.png") !default;
$form-rating-input-empty-image-3x: url("~images/star/star-empty@3x.png") !default;
$form-rating-input-image-dimension: 48px !default;

// Message
// - Error

$message-background-color: #fef4f3 !default;
$message-border-color: #e7e5e4 !default;
$message-border-style: solid !default;
$message-border-width: 1px !default;
$message-max-width: 560px !default;
$message-padding: 24px !default;

$message-content-color: #585858 !default;
$message-content-font-size: 14px !default;
$message-content-line-height: normal !default;
$message-content-margin: 0 !default;

$message-heading-font-size: 14px !default;
$message-heading-font-weigth: 700 !default; // Bold
$message-heading-line-height: normal !default;
$message-heading-margin: 0 !default;
$message-heading-error-color: #f13031 !default;

$message-error-border-left-color: #f13031 !default;
$message-error-border-left-style: solid !default;
$message-error-border-left-width: 3px !default;
$message-error-padding: 24px 24px 24px 94px !default;
$message-error-before-background-color: #fef4f3 !default;
$message-error-before-background-image: url("~images/message/error.png") !default;
$message-error-before-background-image-2x: url("~images/message/error@2x.png") !default;
$message-error-before-background-image-3x: url("~images/message/error@3x.png") !default;
$message-error-before-background-position: 50% 50% !default;
$message-error-before-background-repeat: no-repeat !default;
$message-error-before-background-size: 18px 18px !default;
$message-error-before-border-color: #f13031 !default;
$message-error-before-border-style: solid !default;
$message-error-before-border-width: 3px !default;
$message-error-before-height: 46px !default;
$message-error-before-width: 46px !default;

// Timer
$timer-background-color: $theme-primary-color !default;
$timer-border-radius: 0 0 8px 8px !default;
$timer-box-shadow-color: rgba(0, 0, 0, 0.50) !default;
$timer-box-shadow-shadows: 0 3px 3px 0 !default;
$timer-min-width: 220px !default;
$timer-min-height: 90px !default;
$timer-opacity: 0.50 !default;
$timer-padding: 24px 24px 24px 70px !default;
$timer-right: 36px !default;
$timer-top: 0 !default;
$timer-z-index: 1000 !default;

$timer-before-background-color: transparent !default;
$timer-before-background-image: url("~images/timer/refresh.png") !default;
$timer-before-background-image-2x: url("~images/timer/refresh@2x.png") !default;
$timer-before-background-image-3x: url("~images/timer/refresh@3x.png") !default;
$timer-before-background-position: 50% 50% !default;
$timer-before-background-repeat: no-repeat !default;
$timer-before-background-size: 32px 32px !default;
$timer-before-height: 32px !default;
$timer-before-left: 24px !default;
$timer-before-margin: 4px 0 0 0 !default;
$timer-before-width: 32px !default;

$timer-high-background-color: #2fd048 !default;
$timer-low-background-color: #ff695a !default;
$timer-medium-background-color: #ffc434 !default;

$timer-countdown-color: #fff !default;
$timer-countdown-font-family: $primary-font-family !default;
$timer-countdown-font-size: 40px !default;
$timer-countdown-font-weight: 700 !default; // Bold
$timer-countdown-line-height: 44px !default;
$timer-countdown-margin: 0 !default;
$timer-countdown-text-align: center !default;
