.language-select-legend {
  position: absolute;
  top: rem(20px);
  right: rem(20px);

  span {
    margin-right: rem(20px);
    cursor: pointer;
    font-size: rem(14px);
  }

  .selected {
    text-decoration: underline;
    font-weight: bold;
  }

  &.top {
    bottom: unset;
    top: rem(20px);
  }

  &.right {
    left: unset;
    right: rem(20px);
  }

  &.left {
    right: unset;
    left: rem(20px);
  }

  &.bottom {
    top: unset;
    bottom: rem(20px);
  }

  .language-select-current-item {
    padding: rem(3px);
    padding-left: rem(12px);
    padding-right: rem(12px);
    border-radius: rem(8px);
    background: $theme-primary-color-extralight-tint;
    min-width: rem(150px);
    padding-left: rem(28px);
    cursor: pointer;

    .language-flag-icon {
      position: absolute;
      left: rem(8px);
      top: calc(50% - 12px);
      color: $theme-accent-color;
    }

    .open-menu-icon {
      position: absolute;
      right: rem(8px);
      top: calc(50% - 12px);
      color: $theme-primary-color-lighter;
    }
  }

  .language-select-item {
    padding: rem(6px);
    line-height: rem(13px);
    cursor: pointer;

    .current-language-label {
      font-weight: 600;
      font-size: rem(13px);
      color: $theme-primary-color;
    }

    .international-language-label {
      font-weight: 400;
      font-size: rem(12px);
    }
  }

  .language-select-menu {
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: rem(48px);
    right: 0;
    background: white;
    box-shadow: 0px 2px 6px rgba($color: #000000, $alpha: 0.3);
    z-index: 10;
    min-width: rem(130px);

    .language-select-item {
      padding: rem(12px);
    }

    .language-select-item:hover {
      background: $theme-primary-color-extralight;
    }
  }
}
