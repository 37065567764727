.input-field-wrapper {
  .input-field {
    display: flex;
  
    .label-container {
      margin-right: 12px;
      min-width: 150px;
      position: relative;
      top: 8px;
  
      label {
        font-family: "Poppins", sans-serif;
        font-weight: 600;
        color: $theme-primary-color-lighter;
      }

      &.is-required {
        &::after {
          content: "*";
          display: inline-block;
          margin-left: 4px;
          color: orangered;
        }
      }
    }
  
    .input-container {
      flex-grow: 1;
    }

    &.w-compact {
      flex-direction: column;

      .label-container, .input-container {
        width: 100%;
      }

      .input-container {
        margin-top: 8px;
      }
    }
  }

  .input-error {
    color: red;
    text-align: right;
    font-size: 14px;
  }
}