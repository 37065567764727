.timer {
    background-color: $timer-background-color;
    border-radius: rem($timer-border-radius);
    box-shadow:
        rem($timer-box-shadow-shadows)
        $timer-box-shadow-color;
    min-width: rem($timer-min-width);
    min-height: rem($timer-min-height);
    padding: rem($timer-padding);
    position: fixed;
    right: rem($timer-right);
    top: rem($timer-top);
    transition: background-color 250ms linear;
    z-index: $timer-z-index;

    @include modifier("high") {
        background-color: $timer-high-background-color;
    }

    @include modifier("low") {
        background-color: $timer-low-background-color;
    }

    @include modifier("medium") {
        background-color: $timer-medium-background-color;
    }

    &:hover {
        &::before {
            opacity: 1.00;
        }
    }

    $timer-button-size: 32px;

    .timer-button {
        display: inline-block;
        width: rem($timer-button-size);
        height: rem($timer-button-size);
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        top: calc(50% - #{$timer-button-size} / 2);
        left: rem(16px);
        opacity: 0.8;
        cursor: pointer;

        &:hover {
            opacity: 1;
        }

        &.timer-start {
            background-image: url("~images/timer/timer-start.png");
        }

        &.timer-stop {
            background-image: url("~images/timer/timer-stop.png");
        }
    }

    .timer-select-options {
        position: absolute;
        z-index: 10;
        margin: 0;
        padding: 0;
        list-style-type: none;
        width: rem(150px);
        left: rem(61px);
        top: rem(80px);
        border-radius: rem(8px);
        overflow: hidden;

        li {
            background-color: darken($color: $timer-background-color, $amount: 10%);
            font-size: rem($timer-countdown-font-size);
            font-weight: $timer-countdown-font-weight;
            line-height: rem($timer-countdown-line-height);
            color: $timer-countdown-color;
            padding: rem(12px);
            border-bottom: rem(1px);
            cursor: pointer;

            &:hover {
                background-color: darken($color: $timer-background-color, $amount: 20%);
            }
        }
    }

    @include element("countdown") {
        display: inline-block;
        position: absolute;
        padding: rem(8px);
        // padding-left: rem(16px);
        padding-right: rem(20px);
        top: rem(16px);
        left: rem(65px);
        color: $timer-countdown-color;
        font-family: $timer-countdown-font-family;
        font-size: rem($timer-countdown-font-size);
        font-weight: $timer-countdown-font-weight;
        line-height: rem($timer-countdown-line-height);
        margin: $timer-countdown-margin;
        text-align: $timer-countdown-text-align;
        cursor: pointer;
        border-radius: rem(8px);

        &:hover {
            background: rgba(0, 0, 0, 0.20);

            &::after {
                content: "▼";
                font-size: rem(12px);
                position: absolute;
                right: rem(6px);
            }
        }
    }
}

@include breakpoint(xlarge down) {
    .timer {
        box-shadow:
            rem($timer-box-shadow-shadows)
            rgba(0, 0, 0, 0.20);
        min-width: rem(120px);
        min-height: rem(70px);
        padding: rem(10px 10px 10px 30px);
        right: rem(20px);

        @include element("countdown") {
            font-size: rem(18px);
            line-height: rem(18px);
            left: rem(40px);
            top: rem(20px);
        }

        .timer-button {
            width: rem(22px);
            height: rem(22px);
            left: rem(12px);
            top: rem(25px);
        }

        .timer-select-options {
            left: 0;
            width: rem(120px);

            li {
                font-size: rem(18px);
            }
        }
    }
}
