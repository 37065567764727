.login-info-view {
  position: absolute;
  top: 0;
  right: 0;
  padding: rem(20px);
  color: $theme-primary-color-white;
  text-align: right;
  font-size: rem(12px);

  .links {
    a {
      margin-left: rem(20px);
      color: $theme-primary-color-white;
      text-transform: uppercase;
    }
  }

  &.light {
    color: $theme-primary-color-white;
  }

  &.dark {
    color: #333;

    .links {
        a {
          color: #333;
        }
      }    
  }

  @media(max-width: 1440px) {
    .organization-label {
      display: none;
    }
  }

  @include breakpoint(small down) {
    .email-label {
      display: none;
    }
  }
}
