.dashboard-decks-view {
  background: white;

  .dashboard-decks-view-content {
    max-width: rem(1440px);
    margin-left: auto;
    margin-right: auto;
    background: white;
  }

  .dashboard-navigation-header {
    padding: rem(20px);
  }
}
