.token-exchange-view-container {
  .token-exchange-view-message {

    .login-logo {
      margin-bottom: 3rem;

      background-image: url("~images/topaasia-big-logo-dark.png");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      height: auto;
      width: 100%;
      min-height: 60px;    
    }

    h1 {
      font-family: "Poppins", sans-serif;
      margin-bottom: 2rem;
      color: $theme-primary-color;
    }

    p {
      margin-bottom: 2rem;
    }
  }
}