.subtle-text-input {
  input {
    padding-top: rem(8px);
    padding-bottom: rem(8px);
    font-size: rem(18px);
    color: $theme-primary-color;
    background: none;
    border: none;
    border-bottom: 2px solid $theme-primary-color-lighter;
    width: rem(360px);
    max-width: 90%;

    &:focus {
      outline: none;
    }
  }

  .max-length-label {
    pointer-events: none;
    font-size: rem(12px);
    color: $theme-primary-color-lighter;
  }
}
