.content {
    align-items: center;
    display: flex;
    height: 100vh;
    justify-items: center;
    padding: rem(40px);
}

@include breakpoint(small down) {
    .content {
        padding: 0;
    }
}
