%button-shared {
  background-color: $theme-action-color;
  border: rem($button-border-width) $button-border-style $button-border-color;
  color: $button-color;
  display: inline-block;
  font-family: $button-font-family;
  font-size: rem($button-font-size);
  font-weight: $button-font-weight;
  letter-spacing: rem($button-letter-spacing);
  min-width: rem($button-min-width);
  padding: rem($button-padding);
  text-align: $button-text-align;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: rem($button-border-radius);
  box-shadow: $button-box-shadow;
  cursor: pointer;
}

%button-disabled-shared {
  background-color: $button-disabled-background-color !important;
  border: $button-disabled-border;
  color: $button-disabled-color;
  opacity: 1;
}

.button {
  @extend %button-shared;

  &.button--action {
    background: url("~images/button/arrow-right.png") right rem(20px) top 50% no-repeat
      $theme-action-color;
    color: #fff;
    background-size: rem(16px 8px);
    padding-right: rem(50px);
    text-align: left;

    @include for-device-pixel-ratio(2) {
      & {
        background-image: url("~images/button/arrow-right@2x.png");
      }
    }

    @include for-device-pixel-ratio(3) {
      & {
        background-image: url("~images/button/arrow-right@3x.png");
      }
    }

    &.button--disabled {
      opacity: 0.5;
    }

    &.noicon {
      background-image: none;
      padding-right: rem($button-padding);
    }
  }

  .fa-button-icon {
    margin-right: rem(12px);
    display: inline-block;
  }

  &.button-secondary {
    background-color: $theme-secondary-action-color;

    &.disabled {
      opacity: 0.5;
    }
  }

  &.button-positive {
    background-color: limegreen;

    &.disabled {
      opacity: 0.5;
    }
  }

  &.button-subtle {
    background: none;
    border-radius: 0;
    box-shadow: none;
    border-bottom: 2px solid rgba($color: $theme-action-color, $alpha: 0.4);
    color: $theme-action-color;
  }

  &.button-link {
    background: none;
    border-radius: 0;
    border-bottom: none;
    color: $theme-action-color;
    padding-left: 0px;
    padding-right: 0px;
  }

  &.button-outlined {
    background: none;
    color: $theme-action-color;
    border: 2px solid $theme-action-color;
    box-shadow: none;
  }

  &.button-cancel-subtle {
    color: $theme-primary-color-lighter;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    background: none;
    border: none;
    box-shadow: none;
    text-decoration: underline;
    text-transform: none;
    font-size: 14px;
  }

  &.button-s {
    padding: rem(10px);
    border-radius: rem(12px);
    padding-left: rem(20px);
    padding-right: rem(20px);
    font-size: rem(14px);
    text-transform: none;
  }

  &.button-xs {
    text-transform: none;
    border-radius: rem(16px);
    text-align: center;
    padding: rem(6px);
    padding-left: rem(12px);
    padding-right: rem(12px);
    line-height: 100%;
    white-space: nowrap;

    .button-icon {
      display: inline-block;
      margin-right: rem(12px);
    }

    &:disabled {
      background: #aaa;
      opacity: 0.5;
      cursor: not-allowed;
    }

    &.subtle, &.button-subtle {
      background: none;
      border-bottom: 2px solid $theme-action-color;
      color: $theme-action-color;
      border-radius: 0;
      box-shadow: none;
    }
  }

  &.button--with-icon {
    background-size: auto 90%;
    background-position: 0% 50%;
    background-repeat: no-repeat;
    padding-left: 44px;
  }

  &.disabled {
    @extend %button-disabled-shared;
    opacity: 0.5;
  }

  @include modifier("disabled") {
    @extend %button-disabled-shared;
    opacity: 0.5;
  }
}
