$suits: (
    "clubs": (
        background-size: rem(31px 24px),
        color: $clubs-color,
        width: rem(31px)
    ),
    "diamonds": (
        background-size: rem(13px 24px),
        color: $diamonds-color,
        width: rem(13px)
    ),
    "hearts": (
        background-size: rem(28px 24px),
        color: $hearts-color,
        width: rem(28px)
    ),
    "spades": (
        background-size: rem(21px 24px),
        color: $spades-color,
        width: rem(21px)
    )
);

@each $suit, $map in $suits {
    @include modifier("suits-#{$suit}") {
        background-image: url("~images/cards/suits/#{$suit}.png");
        background-position: left rem(8px) top rem(8px);
        background-size: map-get($map, "background-size");
        background-repeat: no-repeat;

        @include for-device-pixel-ratio(2) {
            & {
                background-image: url("~images/cards/suits/#{$suit}@2x.png");
            }
        }

        @include for-device-pixel-ratio(3) {
            & {
                background-image: url("~images/cards/suits/#{$suit}@3x.png");
            }
        }

        &::after {
            background: url("~images/cards/suits/#{$suit}.png") 0 0 no-repeat transparent;
            background-size: map-get($map, "background-size");
            bottom: rem(8px);
            content: "";
            display: block;
            height: rem(24px);
            position: absolute;
            right: rem(8px);
            transform: rotate(180deg);
            backface-visibility: hidden;
            width: map-get($map, "width");

            @include for-device-pixel-ratio(2) {
                & {
                    background-image: url("~images/cards/suits/#{$suit}@2x.png");
                }
            }

            @include for-device-pixel-ratio(3) {
                & {
                    background-image: url("~images/cards/suits/#{$suit}@3x.png");
                }
            }
        }

        .card-face__heading {
            border-bottom: 2px solid map-get($map, "color");
            color: map-get($map, "color");
            min-width: 100%;
            padding: rem(0 0 8px 0);
        }
    }
}
