
.purchasable-product-item {
  border-radius: rem(8px);
  padding: rem(8px); // the "border" width
  box-shadow: 0px 8px 10px rgba($color: #000000, $alpha: 0.3);
  width: rem(260px);
  background: white;
  margin-bottom: rem(12px);
  font-family: "Poppins", sans-serif;
  font-weight: 600;

  .purchasable-product-item-content {
    border-radius: rem(8px);
    text-align: center;
    height: 100%;
    background: rgb(216,169,252);
    background: linear-gradient(126deg, rgba(216,169,252,1) 0%, rgba(189,101,145,1) 100%);
  
    .title {
      color: white;
      font-size: rem(18px);
      padding-top: rem(20px);
    }

    .description {
      color: white;
      font-size: rem(16px);
      padding-left: rem(20px);
      padding-right: rem(20px);
    }

    .price-per-unit {
      color: white;
      font-size: rem(20px);
      background: rgba($color: #fff, $alpha: 0.33);
      padding: rem(8px);
      min-height: rem(100px);
      display: flex;
      align-items: center;

      .price-with-vat {
        text-align: right;
        margin: auto;
        display: inline-block;
      }

      .tax {
        font-size: rem(16px);
        font-weight: normal;
      }
    }

    .actions {
      margin-top: rem(20px);
      margin-bottom: rem(20px);
    }
  }
}