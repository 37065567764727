.dashboard-checkout-result-view {
  width: rem(1100px);
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: rem(64px);

  .successful-order-container {
    padding: rem(40px);
    margin-top: rem(24px);
    margin-bottom: rem(48px);

    .inline-loading-spinner {
      margin: auto;
    }
  }

  .order-details-container {
    margin-top: rem(48px);
  }

  .product-information-container {
    list-style-type: none;
    padding: 0;

    .dashboard-icon {
      margin-right: rem(8px);
    }

    li {
      font-weight: 600;
    }
  }
}