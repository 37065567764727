.accordion {
  padding: rem(12px);
  border-radius: rem(4px);
  transition: all 500ms;
  
  .accordion-controls {
    display: flex;
    align-items: center;
    cursor: pointer;

    .accordion-header {
      color: $theme-primary-color;
    }

    .accordion-arrow {
      margin-left: rem(8px);
      transition: all 200ms;
    }
  }

  &.disabled {
    opacity: 0.5;

    .accordion-controls {
      cursor: default;
    }
  }

  .accordion-content {
    padding: rem(12px);
    border-radius: rem(4px);
  }

  &:hover {
    background: $theme-accent-color;
  }

  &.open {
    background: $theme-accent-color-extralight;
    margin-bottom: rem(12px);

    .accordion-arrow {
      transform: rotate(90deg) translateX(-1px);
    }
  }

  .subtitled-accordion-header-with-icon {
    margin-top: 8px;
    margin-bottom: 8px;
    width: calc(100% - 30px);
    display: flex;
    align-items: center;

    h3 {
      margin: 0;
      color: $theme-complementary-color;
      font-size: 18px;
    }

    p {
      margin: 0;
      font-size: 16px;
      font-weight: normal;
    }

    .icon-container {
      color: $theme-complementary-color;
      margin-left: 8px;
      margin-right: 16px;
      font-size: 24px;
      width: 30px;
      text-align: center;
    }
  }
}