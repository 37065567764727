.card-payment-method {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: rem(24px);

  padding: rem(8px);
  padding-left: rem(20px);
  padding-right: rem(20px);
  background: $theme-primary-color-lighter;
  border-radius: rem(4px);
  color: white;

  .card-brand-container {
    display: flex;
    align-items: center;

    .card-icon {
      width: rem(25px);
      height: rem(25px);
      
      background-image: url("~images/dashboard/payment-card-icon.png");
      background-size: contain;
      margin-right: rem(8px);
    }
  
    .card-brand {
      font-weight: 600;
      text-transform: uppercase;
    }
  }

  .card-number {
    margin-left: rem(12px);
    .card-number-hidden-part {
      opacity: 0.5;
    }
  }
}
