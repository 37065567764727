.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #aaa;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #aaa transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #cef;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #cef;
  border-color: #cef transparent #cef transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.lds-loader {
  font-size: 10px;
  margin: 0 auto;
  width: rem(30px);
  height: rem(30px);
  border-radius: 50%;
  background: #a0afe3;
  background: -moz-linear-gradient(left, #a0afe3 10%, rgba(160,175,227, 0) 42%);
  background: -webkit-linear-gradient(left, #a0afe3 10%, rgba(160,175,227, 0) 42%);
  background: -o-linear-gradient(left, #a0afe3 10%, rgba(160,175,227, 0) 42%);
  background: -ms-linear-gradient(left, #a0afe3 10%, rgba(160,175,227, 0) 42%);
  background: linear-gradient(to right, #a0afe3 10%, rgba(160,175,227, 0) 42%);
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.lds-loader:before {
  width: 50%;
  height: 50%;
  background: #a0afe3;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
}

.lds-loader:after {
  background: #a0afe3;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.spinning-circle {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 4px solid rgba(255,255,255,0);
  border-top-color: 4px solid #7fc4d1;
  border-right-color: 4px solid #7fc4d1;
  -webkit-animation: single2 2s infinite linear;
  animation: single2 2s infinite linear;
}

@-webkit-keyframes single2 {
  0% {
     -webkit-transform: rotate(0deg);
     transform: rotate(0deg);
     border-top-color: #7fc4d1;
     border-right-color: #7fc4d1;
  }
  50% {
     border-top-color: #1f4f58;
     border-right-color: #1f4f58;
  }
  100% {
     -webkit-transform: rotate(720deg);
     transform: rotate(720deg);
     border-top-color: #7fc4d1;
     border-right-color: #7fc4d1;
  }
}

@keyframes single2 {
  0% {
     -webkit-transform: rotate(0deg);
     transform: rotate(0deg);
     border-top-color: #7fc4d1;
     border-right-color: #7fc4d1;
  }
  50% {
     border-top-color: #1f4f58;
     border-right-color: #1f4f58;
  }
  100% {
     -webkit-transform: rotate(720deg);
     transform: rotate(720deg);
     border-top-color: #7fc4d1;
     border-right-color: #7fc4d1;
  }
}

.common-loading-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}

.inline-loading-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: rem(40px);
  height: rem(40px);
}
