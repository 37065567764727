.deck-color {
  width: rem(48px);
  height: rem(48px);
  border-radius: rem(8px);
  margin-right: rem(8px);
  margin-bottom: rem(8px);
  background-size: 150% auto;
  background-position: center;
  cursor: pointer;

  &.selected {
    border: 3px solid $theme-complementary-color;
  }

  &.charcoal {
    background-image: url("~images/decks/crystals/Charcoal.png");
  }

  &.cadmium-red {
    background-image: url("~images/decks/crystals/CadmiumRed.png");
  }

  &.cadmium-yellow {
    background-image: url("~images/decks/crystals/CadmiumYellow.png");
  }

  &.cerulean-blue {
    background-image: url("~images/decks/crystals/CeruleanBlue.png");
  }

  &.purple {
    background-image: url("~images/decks/crystals/Purple.png");
  }

  &.cobalt-blue {
    background-image: url("~images/decks/crystals/CobaltBlue.png");
  }

  &.cobalt-teal {
    background-image: url("~images/decks/crystals/CobaltTeal.png");
  }

  &.neutral-grey {
    background-image: url("~images/decks/crystals/NeutralGrey.png");
  }

  &.phthalo-turquoise {
    background-image: url("~images/decks/crystals/PhthaloTurquoise.png");
  }

  &.preussian-blue {
    background-image: url("~images/decks/crystals/PreussianBlue.png");
  }

  &.titanium-white {
    background-image: url("~images/decks/crystals/TitaniumWhite.png");
  }

  &.titanium-white {
    background-image: url("~images/decks/crystals/TitaniumWhite.png");
  }

  &.black-red {
    background-image: url("~images/decks/crystals/BlackRed.png");
  }

  &.blue-green-turquoise {
    background-image: url("~images/decks/crystals/BlueGreenTurquoise.png");
  }

  &.space-grey {
    background-image: url("~images/decks/crystals/SpaceGrey.png");
  }

  &.pink-red-white {
    background-image: url("~images/decks/crystals/PinkRedWhite.png");
  }

  &.lime-green {
    background-image: url("~images/decks/crystals/LimeGreen.png");
  }

  &.dark-purple {
    background-image: url("~images/decks/crystals/DarkPurple.png");
  }
}