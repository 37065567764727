.action-bar-layout {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  height: rem(70px);
  background: $theme-primary-color-darker;
  display: flex;
  align-items: center;

  .action-bar-error-indicator {
    position: absolute;
    top: rem(-48px);
    right: rem(20px);
    background: orangered;
    color: white;
    font-weight: 600;
    font-size: rem(14px);
    font-family: "Poppins", sans-serif;
    border-radius: rem(8px);
    padding: rem(12px);
    padding-top: rem(8px);
    padding-bottom: rem(8px);
    cursor: pointer;

    &:hover {
      transform: scale(1.02);
      background: darken($color: orangered, $amount: 10%);
    }
  }

  .action-bar-errors-modal {
    .modal-view-content {
      padding: rem(40px);
      max-height: 80%;
      width: rem(700px);
      overflow: overlay;
  
      h1 {
        font-family: "Poppins", sans-serif;
        font-size: rem(22px);
        color: $theme-primary-color;
        text-align: center;
      }
  
      ul {
        list-style-type: none;
        padding-left: 0;
      }
  
      li {
        padding: rem(12px);
        background: #eee;
        margin-bottom: rem(8px);
        border-radius: rem(8px);
        font-weight: 600;
        font-family: "Poppins", sans-serif;
      }
  
      .actions {
        text-align: center;
      }
    }
  }
}
