@include modifier("perspectives") {
    background:
        $cards-perspectives-background-image
        $cards-perspectives-background-position
        $cards-perspectives-background-repeat
        $cards-perspectives-background-color;
    background-size: rem($cards-perspectives-background-size);
    opacity: 1;

    @include for-device-pixel-ratio(2) {
        & {
            background-image: $cards-perspectives-background-image-2x;
        }
    }

    @include for-device-pixel-ratio(3) {
        & {
            background-image: $cards-perspectives-background-image-3x;
        }
    }

    .card-face__description {
        bottom: rem(16px);
        color: #fff;
        font-weight: 600; // Semi-Bold
        letter-spacing: rem(0.5px);
        margin: 0;
        padding: rem(0 10px);
        position: absolute;
    }

    .card-face__heading {
        border: none;
        color: #fff;
        font-size: rem(18px);
        margin: auto 0;
    }
}