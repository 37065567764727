/* Typeface definitions
---------------------------------------------------------------------------- */

/* Work Sans */

@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* Poppins */

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* Typography
---------------------------------------------------------------------------- */

.link {
    &:link,
    &:visited {
        color: $link-color;
        outline: none;
        text-decoration: none;
    }
    &:focus {
        text-decoration: underline;
    }

    &:hover,
    &:visited:hover {
        color: $link-color-hover;
        text-decoration: underline;
    }

    @include modifier("back") {
        font-size: rem($link-back-font-size);
        margin-left: rem(10px);

        &:link,
        &:visited {
            color: $link-back-color;
            text-decoration: underline;
        }

        &:hover,
        &:visited:hover {
            color: $link-back-color-hover;
        }
    }

    @include modifier("separator-left") {
        &::before {
            content: "|";
            display: inline-block;
            margin: rem(0 10px);
        }
    }
}

%headings-shared {
    color: $headings-shared-color;
    font-weight: $headings-shared-font-weight;
    line-height: $headings-shared-line-height;
}

.text {
    color: $text-color;
    font-family: $text-font-family;
    font-size: rem($text-font-size);
    letter-spacing: rem($text-letter-spacing);
    line-height: rem($text-line-height);

    @include modifier("h1") {
        @extend %headings-shared;
        font-size: rem($text-h1-font-size);
        line-height: rem($text-h1-line-height);
        letter-spacing: rem($text-h1-letter-spacing);
    }

    @include modifier("h2") {
        @extend %headings-shared;
        font-size: rem($text-h2-font-size);
    }

    @include modifier("legend") {
        font-size: rem($text-legend-font-size);
        line-height: rem($text-legend-line-height);
        margin-bottom: rem($text-legend-margin-bottom);

        .link:link,
        .link:visited {
            color: $text-legend-link-color;
        }
    }

    @include modifier("success") {
        color: $text-success-color;
        font-weight: $text-success-font-weight;
    }

    @include modifier("whitespace-top") {
        margin-top: rem(40px);
    }
}

@include breakpoint(small down) {
    .text {
        font-size: rem($text-font-size - 2);
        line-height: rem($text-line-height - 2);

        @include modifier("h1") {
            font-size: rem($text-h1-font-size - 18);
            line-height: rem($text-h1-line-height - 18);
        }

        @include modifier("legend") {
            margin-bottom: rem(round($text-legend-margin-bottom * 0.5));
            text-align: center;
        }
    }
}
