/* Global definitions
---------------------------------------------------------------------------- */

html {
    box-sizing: border-box;
    font-size: $base-font-size;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

body {
    // background:
    //     $body-background-image
    //     $body-background-position
    //     $body-background-repeat
    //     $body-background-color;
    // background-attachment: fixed;
    // background-size: $body-background-size;
    color: $body-color;
    font-family: $base-font-family;
    font-size: $base-font-size;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: $base-line-height;
    min-height: 100vh;

    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;

    @include for-device-pixel-ratio(2) {
        & {
            // background-image: $body-background-image-2x;
        }
    }

    @include for-device-pixel-ratio(3) {
        & {
            // background-image: $body-background-image-3x;
        }
    }
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

.reselect {
    -webkit-touch-callout: initial; /* iOS Safari */
    -webkit-user-select: initial; /* Safari */
    -khtml-user-select: initial; /* Konqueror HTML */
    -moz-user-select: initial; /* Old versions of Firefox */
    -ms-user-select: initial; /* Internet Explorer/Edge */
    user-select: initial; /* Non-prefixed version */
}

.small-margin {
    display: inline-block;
  
    &.margin-right {
      margin-right: rem(8px);
    }
    &.margin-left {
      margin-left: rem(8px);
    }
}