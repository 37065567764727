.navigation-card-grid {
  max-width: rem(650px);
  margin: auto;
  margin-top: rem(40px);

  @include breakpoint(large up) {
    display: flex;

    .navigation-card-item {
      margin: 8px;
      flex-grow: 1;
      width: 50%;
    }
  }

  .navigation-card-item {
    cursor: pointer;
    transition: transform 200ms;

    &:hover {
      h2 {
        text-decoration: underline;
      }
    }

    h2 {
      text-align: center;
      font-family: "Poppins", sans-serif;
      color: $theme-primary-color;
      font-size: rem(18px);

      @include breakpoint(small down) {
        text-decoration: underline;
      }
    }

    p {
      color: white;
      font-size: rem(14px);
      line-height: 1.3em;
      color: $theme-primary-color-lighter;
      text-align: center;
    }

    .navigation-item-icon {
      width: 70%;
      height: rem(128px);
      background-size: contain;
      background-repeat: no-repeat;
      background-position-x: 50%;
      margin: auto;
      margin-top: rem(20px);

      &.join-game {
        background-image: url("~images/intro/join-game-icon-large.svg?url");
      }

      &.login {
        background-image: url("~images/intro/login-icon-large.svg?url");
      }
    }

    &.item-login {      
      @include breakpoint(small down) {
        background: none;

        .navigation-item-icon {
          display: none;
        }

        h2 {
          font-weight: normal;
          font-size: 16px;
          text-decoration: underline;

          &::after {
            content: ">";
            display: inline-block;
            margin-left: 8px;
          }
        }

        p {
          display: none;
        }
      }
    }
  }

  @include breakpoint(small down) {
    max-width: none;
    width: 100%;
    flex-direction: column;

    .navigation-card-item {
      margin-bottom: rem(20px);
    }
  }
}