.deck-preview {
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;

  .deck-preview-cards {
    padding: rem(30px);
    padding-left: rem(70px);
    
    h2 {
      font-family: "Poppins", sans-serif;
      color: $theme-primary-color;
    }

    h3 {
      text-align: center;
      text-transform: uppercase;
      font-family: "Poppins", sans-serif;
      color: $theme-primary-color;

      border-bottom: 1px solid rgba(0, 0, 0, 0.25);
      padding-bottom: rem(10px);
      margin-bottom: rem(20px);
    }

    .deck-preview-deck-suit-cards {
      display: flex;
      flex-wrap: wrap;

      .rotating-card-container {
        margin: rem(12px);

        .card-face--heading {
          font-size: rem(16px);
        }
      }
    }

    .rotating-card-container {
      position: relative;
    }
  }

}
