.text-input {
  padding: 8px;
  border: 1px solid $theme-primary-color-extralight-tint;
  background-color: white;
  border-radius: 8px;
  width: 100%;

  &.underlined {
    border: none;
    border-radius: 0;
    background: none;
    border-bottom: 1px solid $theme-primary-color-extralight;

    &:focus {
      outline: none;
      background: rgba($color: white, $alpha: 0.5);
    }
  }

  &.invalid {
    border-color: red;

    &::placeholder {
      color: rgba($color: red, $alpha: 0.5);
    }
  }
}