.resizable-column-layout {
  .resize-handle {
    position: relative;
    transition: all 500ms;
    cursor: col-resize;
    border-radius: 4px;
    background-repeat: no-repeat;
    background-position: center;
    width: 8px;

    &:hover {
      width: 24px;
      background-color: $theme-primary-color-extralight;
    }
  }
}
