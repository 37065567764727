.picked-card {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    .card-face {
        grid-column-start: 1;
        grid-row-start: 1;
        margin-top: rem(44px);
    }

    .card-face--perspectives {
        grid-column-start: 1;
        grid-row-start: 1;
        margin-top: 0;
        opacity: 1.00;
        z-index: -1;

        .card-face__description {
            align-items: center;
            bottom: auto;
            display: flex;
            min-height: rem(30px);
            top: rem(8px);
        }
    }
}

@include breakpoint(small down) {
    .picked-card {
        grid-template-columns: repeat(1, 1fr);
    }
}
