.multiple-choice-bar {
  padding: rem(3px);
  background: $theme-primary-color-extralight-tint;
  margin-bottom: rem(20px);
  border-radius: rem(8px);

  .multiple-choice-item {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: rem(6px);
    border-radius: rem(6px);
    font-family: "Poppins", sans-serif;
    color: $theme-primary-color;
    font-size: 14px;
    cursor: pointer;

    &.selected {
      background: $theme-accent-color;
      color: white;
    }
  }
}
