.play-view-loading-screen {
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background: $theme-primary-color;
  background-image: url("~images/card-background-dark-large.png");
  background-size: auto 1024px;
}

.play-view-login-info {
  right: rem(20px);
}

.play-view-container {
  .play-view-content {
    h1.play-heading {
      margin-top: rem(30px);
      margin-bottom: rem(30px);
      font-size: rem(24px);
      text-align: center;
      color: $theme-primary-color;
      font-weight: 800;
    }

    .help-info-container {
      margin-top: rem(80px);

      h2 {
        font-family: "Poppins", sans-serif;
        color: $theme-primary-color-lighter;
      }

      p {
        color: $theme-primary-color-lighter;
      }
    }
  }

}

@include breakpoint(medium down) {
  .play-view-container {
    padding: rem(0px);

    .play-view-content {
      display: block;
      padding: rem(0px);
      padding-top: rem(60px);
  
      .navigation-items-container {
        width: 100%;
        flex-direction: column;
  
        .navigation-item {
          &:first-child {
            margin-bottom: rem(20px);
          }
        }
      }

      h1 {
        margin-top: rem(100px);
      }

      .help-info-container {
        padding: rem(20px);
      }
    }
  }
}