body.body-intro-layout {
  background-image: none;
  background: $theme-primary-color;
  background-image: url("~images/card-background-dark-large.png");
  background-size: auto 1024px;

  @include breakpoint(small down) {
    background: white;
  }
}

.intro-layout {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;

  @include breakpoint(small down) {
    background: white;
    .intro-layout-topaasia-decoration {
      display: none;
    }
  }

  @include breakpoint(small down) {
    width: 100%;
  }

  @include breakpoint(large up) {
    @media(max-height: 700px) {
      .intro-layout-topaasia-decoration {
        display: none;
      }
    }
  }

  .intro-content {
    position: relative;
    background: white;
    width: 100%;
    max-width: rem(1000px);
    padding: rem(60px);

    @include breakpoint(small down) {
      box-shadow: none;
    }
  }

  @include breakpoint(small down) {
    .intro-content {
      padding-left: rem(20px);
      padding-right: rem(20px);
      padding-top: rem(60px);
      padding-bottom: rem(20px);
    }
  }
}
