.error-boundary-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;

  .error-boundary-content {
    width: rem(600px);
    max-width: 100%;
    background: $theme-primary-color-extralight-tint;
    border-radius: rem(8px);
    padding: rem(30px);

    h1 {
      margin-bottom: rem(48px);
      font-family: "Poppins", sans-serif;
    }
  }
}