.context-menu {
  position: relative;

  .context-menu-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem(35px);
    height: rem(35px);
    border-radius: 50%;
    color: #455;
    user-select: none;
    font-size: rem(20px);
    font-family: sans-serif;
    font-weight: 900;
    cursor: pointer;
    position: relative;
    top: -6px;

    &:hover {
      background: $theme-primary-color-extralight;
      color: white;
    }

    &.open {
      background: $theme-primary-color-lighter;
      box-shadow: none;
      color: white;
    }
  }

  .context-menu-content {
    width: rem(250px);
    background: white;
    position: absolute;
    top: rem(0px);
    left: rem(45px);
    transition: all 250ms;
    transform-origin: 0% 0%;
    z-index: 10000;
    filter: drop-shadow(2px 4px 6px $theme-primary-color-extralight);

    &.content-left {
      left: unset;
      right: rem(45px);
      transform-origin: 100% 0%;
    }

    &.closed {
      transform: scale(0);
      opacity: 0;
    }

    .context-menu-item {
      height: rem(40px);
      color: $theme-primary-color;
      font-family: "Poppins", sans-serif;
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: rem(14px);
      cursor: pointer;

      &:hover {
        background: $theme-primary-color-extralight;
      }

      .dashboard-icon {
        margin-left: rem(8px);
        margin-right: rem(8px);
        transform: scale(0.7);
      }
    }

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }
  }
}