.fixed-error-status-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: rem(40px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  background: orangered;
  color: white;
  padding: rem(2px);
  padding-left: rem(12px);
  padding-right: rem(12px);
  font-size: rem(14px);
}
