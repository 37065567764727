@include modifier("topaasia") {
    background-image:
        $cards-topaasia-background-image,
        $cards-perspectives-background-image;
    background-position:
        rem($cards-topaasia-background-position),
        rem($cards-perspectives-background-position);
    background-size:
        rem($cards-topaasia-background-size),
        rem($cards-perspectives-background-size);
    background-repeat: $cards-perspectives-background-repeat;
    margin: 0 auto;

    @include for-device-pixel-ratio(2) {
        & {
            background-image:
                $cards-topaasia-background-image-2x,
                $cards-perspectives-background-image-2x;
        }
    }

    @include for-device-pixel-ratio(3) {
        & {
            background-image:
                $cards-topaasia-background-image-3x,
                $cards-perspectives-background-image-3x;
        }
    }
}

@include breakpoint(medium down) {
    @include modifier("topaasia") {
        margin: rem(0 auto 40px auto);
    }
}
