.dashboard-action-items-view {
  width: rem(1280px);
  max-width: calc(100% - 40px);
  margin: auto;

  .dashboard-navigation-header {
    margin-top: 40px;
    margin-bottom: 60px;
  }

  .action-item-results-container {
    padding-bottom: 60px;
  }
}
