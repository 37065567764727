.radio-input {
  list-style-type: none;
  padding: 0;
  font-family: "Poppins", sans-serif;

  &.disabled {
    opacity: 0.3;

    li {
      cursor: default;
    }
  }

  li {
    padding: rem(8px);
    border: 1px solid rgba($color: #000000, $alpha: 0.1);
    border-radius: rem(6px);
    margin-bottom: rem(8px);
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;

    .tooltip-icon {
      display: inline-block;
      font-size: 1.35rem;
      width: 25px;
      margin-left: 12px;
      text-align: center;
      color: #fff;
      cursor: pointer;
      border-radius: 15px;
      background-color: #fea500;
      font-weight: 500;
      &::before {
        content: '?';
      }
    }  

    * {
      cursor: pointer;
    }

    &.disabled {
      cursor: default;
      opacity: 0.3;
    }

    .radio-option-control-slot {
      $size: rem(30px);
      background-color: $theme-primary-color-white;
      width: $size;
      height: $size;
      border-radius: rem(15px);
      display: flex;
      align-items: center;
      justify-content: center;

      .radio-option-control-selected {
        $size: rem(10px);
        background: $theme-primary-color;
        width: $size;
        height: $size;
        border-radius: rem(5px);
      }

    }

    .radio-option-label {
      display: block;
      margin-left: rem(12px);
    }

  }

  &.light {
    .radio-option-control-slot {
      background-color: $theme-primary-color-white;
    }
  }
}
