.flash-message {
  padding: rem(12px);
  border-radius: rem(8px);
  margin-bottom: rem(24px);
  position: relative;

  &.info {
    background: darken($color: $theme-primary-color-extralight, $amount: 10%);
    background: rgb(237, 248, 255);
    background: linear-gradient(160deg, rgba(237, 248, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    color: $theme-primary-color-lighter;
  }

  &.warning {
    background: orange;
    color: white;
  }

  &.error {
    background: orangered;
    color: white;
  }

  .flash-message-title {
    font-family: "Poppins", sans-serif;
    margin: 0;
    margin-bottom: rem(8px);
  }

  .flash-message-message {
    font-weight: 600;
    margin: 0;
  }

  .flash-message-dismiss-button {
    position: absolute;
    right: 12px;
    top: calc(50% - 12px);
    font-weight: bold;
    color: white;
    cursor: pointer;
  }

  p {
    text-align: center;
  }
}