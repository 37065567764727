.modal-view-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  transform: translateZ(10000px);

  background: rgba($color: #000, $alpha: 0.15);

  .modal-view-layout {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .modal-view-content {
      min-width: 400px;
      min-height: 300px;
      background: white;
      border-radius: rem(10px);
      box-shadow: 0px 3px 6px rgba($color: $theme-primary-color, $alpha: 0.50);
    }
  }
}