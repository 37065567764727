@mixin player-vote-result-shared {
  position: absolute;
  display: block;
  pointer-events: none;
  top: rem(8px);
  right: rem(8px);
  border-radius: 16px;
  background-image: url("~images/vote/vote-thumb-icon.png");
  background-size: rem(18px) rem(18px);
  background-position: calc(100% - 8px) 50%;
  background-repeat: no-repeat;
  background-color: #2fd048;
  text-align: right;
  padding: 3px;
  padding-right: 34px;
  padding-left: 8px;
  font-weight: 600;
  font-size: rem(16px);
  color: white;
  transform: translateZ(100px);
}
