.topaasia-legend-decoration {
  position: absolute;
  top: rem(30px);
  left: rem(30px);
  width: 400px;
  height: 150px;
  max-width: 90%;
  background: url("~images/background.png");
  background-size: contain;
  pointer-events: none;
  background-repeat: no-repeat;
}

.topaasia-website-legend {
  position: absolute;
  top: rem(20px);
  left: rem(20px);
  margin: 0;
  a.link { color: #555; }

  @include breakpoint(small down) {
    display: none;
  }
}

.topaasia-suits-row-decoration {
  margin-top: rem(20px);
  margin-bottom: rem(20px);
  height: rem(40px);
  background-image: url("~images/intro/suits-row-decorator.png");
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.topaasia-logo-decoration {
  margin-top: rem(20px);
  margin-bottom: rem(20px);
  width: 100%;
  height: rem(100px);
  background-image: url("~images/topaasia-big-logo-white.png");
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;

  &.dark {
    background-image: url("~images/topaasia-big-logo-dark.png");
  }

  @include breakpoint(medium down) {
    transform: scale(0.8);
  }
}