.search-bar {
  padding: rem(12px);
  padding-left: rem(50px);
  border: rem(1px) solid transparent;
  border-bottom: 1px solid #ccc;
  background: white;

  background-image: url("~images/dashboard/search-icon-dark.png");
  background-size: auto 90%;
  background-position: 8px 50%;
  background-repeat: no-repeat;

  &:focus {
    border-radius: 8px;
    outline: none;
    border: rem(1px) solid transparentize($color: #435A69, $amount: 0.5);
  }
}