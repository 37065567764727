@import "../../../components/player-vote/player-vote-result-shared";

.card-stack {
  display: grid;
  position: relative;
  padding: rem(15px);

  .rotating-card-container {
    max-width: rem(220px);
    position: relative;
  }

  * .rotating-card-container {
    position: absolute;
    top: 0;
    left: 0;
  }

  * {
    &:hover {
      &::before {
        background: none !important;
        box-shadow: none !important;
      }
    }
  }
}

.rotating-card-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: top 300ms;
}

.card-face {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  align-items: center;
  backface-visibility: hidden;
  background-color: $cards-background-color;
  border-radius: rem(8px);
  box-shadow: rem(1px 2px 3px 0) rgba(0, 0, 0, 0.32);
  transform: translateZ(20px);

  padding: rem(15px);
  position: relative;
  width: rem(200px);
  height: rem(240px);

  @include element("description") {
    @extend %cards-text-shared;

    font-size: rem(12px);
    letter-spacing: rem(0.14px);
  }

  @include element("heading") {
    @extend %cards-text-shared;

    color: #515151;
    font-size: rem(16px);
    font-weight: 600; // Semi-Bold
    letter-spacing: rem(1px);
    max-width: 100%;

    @include modifier("uppercase") {
      text-transform: uppercase;
    }
  }

  &__heading+&__description {
    margin-top: rem(14px);
  }

  @include element("remove") {
    background: url("~images/cards/remove.png") 50% 50% no-repeat transparent;
    background-size: rem(11px 11px);
    display: block;
    height: rem(24px);
    overflow: hidden;
    position: absolute;
    right: rem(3px);
    text-indent: 100%;
    top: rem(3px);
    transition: transform 100ms ease-in-out;
    white-space: nowrap;
    width: rem(24px);

    @include for-device-pixel-ratio(2) {
      & {
        background-image: url("~images/cards/remove@2x.png");
      }
    }

    @include for-device-pixel-ratio(3) {
      & {
        background-image: url("~images/cards/remove@3x.png");
      }
    }
  }

  @import "../components/cards/suits";
  @import "../components/cards/perspectives";
  @import "../components/cards/topaasia";

  @include modifier("add") {

  }

  @include modifier("sides-back") {
    background:
      $cards-perspectives-background-image $cards-perspectives-background-position $cards-perspectives-background-repeat $cards-perspectives-background-color;
    background-size: rem($cards-perspectives-background-size);

    * {
      display: none;
    }
  }

  @include modifier("with-shadow") {
    &::after {
      background-color: #d8d8d8;
      bottom: rem(-30px);
      content: "";
      display: block;
      filter: blur(rem(4px));
      height: rem(14px);
      left: 0;
      margin: 0 auto;
      position: absolute;
      right: 0;
      width: rem(162px);
    }

    p {
      display: none;
      visibility: hidden;
    }
  }
}

.card-back {
  position: absolute;
  transform-style: preserve-3d;
  width: 100%;
  height: 100%;
  display: flex;
  backface-visibility: hidden;
  transform: rotateY(180deg) translateZ(5px);
  border-radius: 8px;
  justify-content: center;

  background:
    $cards-perspectives-background-image $cards-perspectives-background-position $cards-perspectives-background-repeat $cards-perspectives-background-color;
  background-size: rem($cards-perspectives-background-size);

  &-playername {
    color: #fff;
    font-size: rem(14px);
    font-family: "Poppins", sans-serif;
    align-self: center;
    font-weight: 600;
    max-width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;

    .player-avatar {
      margin: auto;
      border-radius: 8px;
      margin-bottom: 8px;
    }
  }
}

.card-container-layout {
  position: relative;
}

.topaasia-choice-card {
  position: relative;
  width: rem(200px);
  height: rem(290px);

  .card-face {
    position: absolute;
    top: rem(40px);
    left: 0;
  }

  .perspective-card {
    position: absolute;
    top: 0;
    left: 0;
  }

  .rotating-card-container {
    &:hover {
      &::before {
        all: initial;
      }
    }
  }

  & .topaasia-choice-vote {
    @include player-vote-result-shared;
    top: rem(30px);
  }

  &:hover {
    @include cards-highlight-shared;

    &::before {
      top: rem(-15px);
      height: rem(300px);
    }
  }

  &.skipped-round {
    &:hover {
      &::before {
        all: unset;
      }
    }
  }
}

@include breakpoint(medium up) {
  .rotating-card-container:last-of-type {
    &:hover {
      @include cards-highlight-shared;
    }

    &.not-selectable, &.not-clickable {
      &:hover {
        &::before {
          all: initial;
        }
      }
    }

    @include element("remove") {
      &:hover {
        transform: scale(1.30);
      }
    }
  }
}
